<template>
    <div>
        <b-modal v-model="modal" hide-footer title="Preview Body" size="lg">
            <template v-if="tabIndex < 3">
                <pre>{{ example_json }}</pre>
            </template>
            <template v-else>
            <div class="label-text mb-2">Send Body</div>
            <pre v-if="send_body">{{ send_body }}</pre>
            <div v-else>{ }</div>
            <div class="label-text my-2">Response Body</div>
            <pre v-if="response_body">{{ response_body }}</pre>
            <div v-else>{ }</div>
        </template>
        </b-modal>
    </div>
</template>
<script>
export default {
    props: {
        tabIndex: {
            default: 0,
        }
    },
    data() {
        return {
            modal: false,
            send_body: '',
            response_body: '',
            example_json: '',
        }
    },
    methods: {
        openModal(item) {
            try {
                if (item.example_json) {
                    let body = JSON.parse(item.example_json);
                    this.example_json = body;
                }


                else if (item.response_body) {
                    let body = JSON.parse(item.response_body);
                    this.response_body = body;
                }

                if (item.send_body) {
                    let body = JSON.parse(item.send_body);
                    this.send_body = body;
                }


                this.modal = true;
            } catch (error) {
                console.log(error);
                this.modal = true;
            }
        },
    }
}

</script>
<style scoped>
::v-deep .modal-body{
    height: 80vh;
    overflow: auto;
}
</style>
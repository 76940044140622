<template>
    <div>
        
        <b-row class="no-gutters align-items-center" style="min-height: 35px">
            <b-col cols="3" md="4" sm="5" lg="6" class="header-wrapper">
                <div>
                    <h1 class="mr-sm-4 header-tablepage">WEBHOOK</h1>
                </div>
            </b-col>
            <b-col class="text-right">
                <b-button class="pointer btn-log" @click="goToLog()" routerPath="/setting/shipping-methods/0">
                    Webhook Log
                </b-button>
            </b-col>
        </b-row>
        <div class="bg-white mt-3 pb-3">
            <b-tabs  v-model="tabIndex">
                <b-tab title="Webhook Url">
                    <WebHookUrls></WebHookUrls>
                </b-tab>
                <b-tab title="Inventory Webhook">
                    <InventoryWebhook ref="inventoryWebhook"></InventoryWebhook>
                </b-tab>
                <b-tab title="Setting">
                    <SettingWebhook ref="settingWebhook"></SettingWebhook>
                </b-tab>
            </b-tabs>

        </div>
    </div>
</template>

<script>

import WebHookUrls from './Tabs/webHookUrl.vue'
import InventoryWebhook from './Tabs/inventoryWebhook.vue'
import SettingWebhook from './Tabs/settingWebhook.vue'


export default {
    components: { WebHookUrls, InventoryWebhook, SettingWebhook },
    data() {
        return {
            tabIndex: 0,
            isOpenLog: false
        };
    },
    methods: {
        goToLog() {
            this.$router.push("/setting/webhook/log");
        }
    },
    watch: {
        tabIndex: {
            handler(newVal) {
                if (newVal === 1) {
                    this.$refs.inventoryWebhook.getData();
                }
                else if (newVal === 2) {
                    this.$refs.settingWebhook.getData();
                }
            },
        }
    }

};
</script>

<style scoped>
::v-deep .btn-log button,
.btn-log {
    color: var(--primary-color) !important;
    border-color: var(--primary-color);
    font-weight: 100;
    background-color: white;
}
</style>
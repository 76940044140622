<template>
    <div>
        <b-container class="no-gutters bg-white py-3">
            <InputText v-for="item in data" :key="item.id" placeholder="" customLabel type="text"
                v-model="item.webhook_url">
                <template v-slot:textFloat>
                    <div class="d-flex justify-content-between mb-1">
                        <div>{{ item.webhook_name }}</div>
                        <div class="f-14 underline cursor-pointer text-highlight"
                            @click="openModalPreview(item)">example Json</div>
                    </div>
                </template>
            </InputText>
            <FooterAction @submit="updateWebHookList(0)" routePath="/setting" />
        </b-container>
        <ModalPreviewBody :tabIndex="0" ref="modal"></ModalPreviewBody>
    </div>
</template>
<script>
import InputText from "@/components/inputs/InputText";
import ModalPreviewBody from "../modalPreviewBody.vue";

export default {
    components: { InputText, ModalPreviewBody },
    data() {
        return {
            data: [],
        };
    },
    methods: {
        async getData() {
            const response = await this.axios(`/webhook/List`);
            this.data = response.data.detail

        },
        async updateWebHookList() {
            this.$bus.$emit("showLoading");
            const response = await this.axios.post(
                `/webhook/Update`,
                this.data
            )
            this.getData();
            this.$bus.$emit("hideLoading");
            if (response.data.result == 1) this.successAlert();
            else this.errorAlert(response.data.message);

        },
        openModalPreview(example_json) {
            this.$refs.modal.openModal(example_json)
        }
    },
    mounted() {
        this.getData();
    },

};
</script>

<style scoped>
.text-highlight {
    color: var(--primary-color);
    font-weight: bold;
    opacity: 1 !important;
    text-decoration: underline;
}
</style>